import { Component, OnInit } from '@angular/core';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionnaireModel } from 'src/app/models/questionnaire.model';
import { QuestionModel, QuestionTypeModel } from 'src/app/models/question.model';
import { AnswerService } from 'src/app/services/answer.service';
import { AnswerModel, AttachmentDetailsModel } from 'src/app/models/answer.model';
import { UserService } from 'src/app/services/user.service';
import { UserQuestionnaireStatus } from 'src/app/models/user.model';

@Component({
  selector: 'app-question-view',
  templateUrl: './question-view.component.html',
  styleUrls: ['./question-view.component.scss']
})
export class QuestionViewComponent implements OnInit {

  questionnaireId: string;
  questionId: string;
  questionnaire: QuestionnaireModel;
  question: QuestionModel;
  answer: AnswerModel;
  editable: boolean;
  multipleChoice: {
    [option: string]: boolean
  };
  questionChain: any[];

  loading: boolean;
  errorMessage: string;

  QuestionTypeModel = QuestionTypeModel;

  constructor(private questionnaireService: QuestionnaireService, private answerService: AnswerService, private route: ActivatedRoute, private router: Router, private userService: UserService) { }

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      this.questionnaireId = routeParams.questionnaireId;
      this.questionId = routeParams.questionId;

      // get question
      this.questionnaireService.getQuestionnaire(this.questionnaireId, true).then(questionnaire => {
        this.questionnaire = questionnaire;

        this.editable = questionnaire.status === UserQuestionnaireStatus.IN_PROGRESS;
        this.question = questionnaire.questions && questionnaire.questions.find(q => q._id === this.questionId);
        if (!this.question) {
          if (this.questionnaire && this.questionnaire.questions && this.questionnaire.questions[0] && this.questionnaire.questions[0]._id) {
            this.router.navigate(["/questions", this.questionnaireId, this.questionnaire.questions[0]._id]);
          } else {
            this.router.navigate(["/home"]);
          }
        } else {

          // get answer
          this.answerService.getAnswer(this.questionnaireId, this.questionId).then(answer => {
            if (answer) {
              this.answer = answer;
              if (this.question.type === QuestionTypeModel.CHECK_BOX) {
                try {
                  this.multipleChoice = JSON.parse(this.answer.value);
                } catch(e) {
                  this.multipleChoice = {};
                }
              }
            } else {
              // Create empty answer
              this.answer = {
                answerId: undefined,
                userId: undefined,
                questionnaireId: this.questionnaireId,
                questionId: this.questionId,
                value: "",
                attachments: []
              }
              if (this.question.type === QuestionTypeModel.CHECK_BOX) {
                this.multipleChoice = {};
              }
              this.answerService.createAnswer(this.questionnaireId, this.questionId, this.answer.value).then(createdAnswer => {
                this.answer.answerId = createdAnswer.answerId;
                this.answer.userId = createdAnswer.userId;
              }).catch(e => {
                console.error(e);
                this.errorMessage = "Er is een fout opgetreden.";
              })
            }

            return this.answerService.getQuestionChain(this.questionnaire).then(chain => {
              return this.answerService.getAnswers(this.questionnaireId).then(answers => {
                this.questionChain = chain.map(item => {
                  let a = answers.find(a => a.questionId === item._id);
                  return {
                    _id: item._id,
                    isEmpty: !a || !a.value,
                    isCurrent: item._id === this.questionId,
                    isPassed: chain.findIndex(q => q._id === this.questionId) > chain.findIndex(q => q._id === item._id)
                  };
                })
              })
            })
          }).catch(e => {
            console.error(e);
            this.errorMessage = "Er is een fout opgetreden.";
          });
        }
      }).catch(e => {
        console.error(e);
        this.errorMessage = "Er is een fout opgetreden.";
      });
    });
  }

  public back() {
    this.loading = true;
    this.errorMessage = '';
    this.save().then(() => {
      return this.answerService.getQuestionChain(this.questionnaire);
    }).then(questionChain => {
      let index = questionChain.indexOf(this.question);
      if (index <= 0) {
        // return to home
        this.router.navigate(["/home"]);
      } else {
        let previousQuestion = questionChain[index - 1];
        this.router.navigate(["/questions", this.questionnaireId, previousQuestion._id]);
      }
    }).catch(e => {
      console.error(e);
      this.errorMessage = 'Er is een fout opgetreden.'
    }).then(() => {
      this.loading = false;
    });
  }

  public next() {
    this.loading = true;
    this.errorMessage = '';
    this.save().then(() => {
      return this.answerService.getQuestionChain(this.questionnaire);
    }).then(questionChain => {
      let index = questionChain.indexOf(this.question);
      if (index + 1 >= questionChain.length) {
        // go to final page
        this.router.navigate(["/questions", this.questionnaireId, "finish"]);
      } else {
        let nextQuestion = questionChain[index + 1];
        this.router.navigate(["/questions", this.questionnaireId, nextQuestion._id]);
      }
    }).catch(e => {
      console.error(e);
      this.errorMessage = 'Er is een fout opgetreden.'
    }).then(() => {
      this.loading = false;
    });
  }

  private async save(): Promise<void> {
    if (this.editable) {
      if (this.question.type === QuestionTypeModel.CHECK_BOX) {
        this.answer.value = JSON.stringify(this.multipleChoice);
      }
      return this.answerService.updateAnswer(this.questionnaireId, this.answer).then();
    }
  }

  public attachmentInputChanged(files) {
    console.log(files);
    for (let file of files) {
      let attachment: AttachmentDetailsModel = {
        name: file.name,
        size: file.size,
        contentType: file.type
      };
      this.answer.attachments.push(attachment);

      this.answerService.uploadAttachment(this.questionnaireId, this.answer.answerId, file, progress => {
        attachment.progress = progress;
      }).then(result => {
        attachment.attachmentId = result.attachmentId;
        attachment.chunks = result.chunks;
      }).catch(e => {
        console.error(e);
        attachment.error = true;
      });
    }
  }

  public removeAttachment(attachment: AttachmentDetailsModel) {
    if (attachment.attachmentId) {
      this.answerService.removeAttachment(this.questionnaireId, this.answer.answerId, attachment).then(() => {
        let index = this.answer.attachments.findIndex(a => a.attachmentId === attachment.attachmentId);
        if (index > -1) {
          this.answer.attachments.splice(index, 1);
        }
      }).catch(e => console.error(e));
    } else {
      let index = this.answer.attachments.findIndex(a => a.attachmentId === attachment.attachmentId);
      if (index > -1) {
        this.answer.attachments.splice(index, 1);
      }
    }
  }

  public downloadAttachment(attachment: AttachmentDetailsModel) {
    this.answerService.downloadUserAttachment(this.questionnaireId, this.answer.answerId, attachment).then(() =>{
      console.info("done");
    }).catch(e => console.error(e));
  }

}
