import { Component, OnInit } from '@angular/core';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { UserService } from 'src/app/services/user.service';
import { AnswerService } from 'src/app/services/answer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserModel } from 'src/app/models/user.model';
import { QuestionnaireModel } from 'src/app/models/questionnaire.model';
import { AnswerModel, AttachmentDetailsModel } from 'src/app/models/answer.model';
import { QuestionTypeModel } from 'src/app/models/question.model';
import { jsPDF } from "jspdf";
import *  as JSZip from "jszip";
import "jspdf-autotable"

@Component({
  selector: 'app-result-view',
  templateUrl: './result-view.component.html',
  styleUrls: ['./result-view.component.scss']
})
export class ResultViewComponent implements OnInit {

  userId: string;
  questionnaireId: string;
  user: UserModel;
  questionnaire: QuestionnaireModel;
  answers: AnswerModel[];

  displayedColumns: string[] = ['question', 'answer', 'attachments'];
  dataSource: any;

  constructor(private userService: UserService, private questionnaireService: QuestionnaireService, private answerService: AnswerService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      this.userId = routeParams.userId;
      this.questionnaireId = routeParams.questionnaireId;
      this.userService.getUser(this.userId).then(user => {
        this.user = user;
      }).catch(e => {
        console.error(e);
      });

      this.questionnaireService.getQuestionnaire(this.questionnaireId).then(questionnaire => {
        this.questionnaire = questionnaire;


        return this.answerService.getUserAnswers(this.questionnaireId, this.userId).then(answers => {
          this.answers = answers;
          this.dataSource = this.answers.map(answer => {
            let question = this.questionnaire.questions.find(q => q._id === answer.questionId);
            let value = answer.value;
            if (question.type === QuestionTypeModel.CHECK_BOX) {
              try {
                let options = JSON.parse(value);
                value = Object.keys(options).filter(option => options[option]).join(", ");
              } catch(e){}
            }
            let questionIndex = this.questionnaire.questions.indexOf(question) + 1;
            let questionTitle = `${questionIndex}. ${question.name}`
            return {
              questionTitle: questionTitle,
              questionIndex: questionIndex,
              answerModel: answer,
              answer: value,
              attachments: answer.attachments
            }
          });
        });
      }).catch(e => {
        console.error(e);
      });
    });
  }

  downloadAttachment(answer: AnswerModel, attachment: AttachmentDetailsModel) {
    this.answerService.downloadAdminAttachment(this.questionnaireId, answer.answerId, attachment, this.userId).catch(e => {
      console.error(e);
    });
  }

  public async exportPdf(): Promise<void> {
    let zip = new JSZip();

    let doc = new jsPDF({
      orientation: "portrait"
    });
    doc.text(`Vragenlijst '${this.questionnaire.name}'`, 10, 20);
    doc.text(`Antwoorden van ${this.user.email}`, 10,27);
    let headers = ['Question', 'Answer', 'Attachments'];

    let data = [];
    for (let item of this.dataSource) {
      let attachmentDescription = " "
      if (item.attachments && item.attachments.length > 0) {
        let zipFolder = zip.folder(`vraag_${item.questionIndex}`);
        attachmentDescription = `${item.attachments.length} attachment(s) (zie map 'vraag_${item.questionIndex}')`;
        for (let attachment of (item.attachments as AttachmentDetailsModel[])) {
          let rawAttachment = await this.answerService.downloadAdminRawAttachment(this.questionnaireId, item.answerModel.answerId, attachment, this.userId)
          zipFolder.file<"uint8array">(attachment.name, rawAttachment);
        }
      }
      data.push([
        item.questionTitle,
        item.answer,
        attachmentDescription
      ])
    }

    (doc as any).autoTable({
      head: [headers],
      body: data,
      columnStyles: {
        0: { cellWidth: 50 },
        1: { cellWidth: 80 },
        2: { cellWidth: 50 }
      },
      margin: {
        top: 40
      }
    })

    // doc.table(10, 40, data, headers.map(header => {
    //   return {
    //     id: header,
    //     name: header,
    //     prompt: header,
    //     width: "auto",
    //     align: "center",
    //     padding: 0
    //   }
    // }) as any, { autoSize: true })
    zip.file("antwoorden.pdf", doc.output());

    zip.generateAsync({type:"base64"}).then(function (base64) {
      window.open(`data:application/zip;base64,${base64}`, "_blank")
    });
  }

}
