import { UserRole } from './user-role';

export interface UserModel {

  userId: string;
  email: string;
  role: UserRole;
  questionnaires: Array<{
    questionnaireId: string,
    status: UserQuestionnaireStatus
  }>

}

export enum UserQuestionnaireStatus {
  IN_PROGRESS = "IN_PROGRESS",
  SENDED = "SENDED"
}