import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatToolbarModule } from "@angular/material/toolbar"
import { MatIconModule } from "@angular/material/icon"
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatMenuModule } from "@angular/material/menu";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatListModule } from "@angular/material/list";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTableModule } from "@angular/material/table";

import { SignupComponent } from './components/signup/signup.component';
import { LoginComponent } from './components/login/login.component'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeComponent } from './components/home/home.component';
import { AdminOverviewComponent } from './components/admin-overview/admin-overview.component';
import { TokenInterceptor } from './services/token-interceptor.service';
import { InviteComponent } from './components/invite/invite.component';
import { CredentialsDialogComponent } from './components/credentials-dialog/credentials-dialog.component';
import { RemoveDialogComponent } from './components/remove-dialog/remove-dialog.component';
import { QuestionnaireCreateComponent } from './components/questionnaire-create/questionnaire-create.component';
import { QuestionnaireEditComponent } from './components/questionnaire-edit/questionnaire-edit.component';
import { QuestionAddComponent } from './components/question-add/question-add.component';
import { UserOverviewComponent } from './components/user-overview/user-overview.component';
import { QuestionViewComponent } from './components/question-view/question-view.component';
import { QuestionnaireFinishComponent } from './components/questionnaire-finish/questionnaire-finish.component';
import { ResultViewComponent } from './components/result-view/result-view.component';
import { StatementViewComponent } from './components/statement-view/statement-view.component';
import { StatementEditComponent } from './components/statement-edit/statement-edit.component';
import { StatementCreateComponent } from './components/statement-create/statement-create.component';

import { MarkdownModule } from "ngx-markdown";

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    LoginComponent,
    HomeComponent,
    AdminOverviewComponent,
    InviteComponent,
    CredentialsDialogComponent,
    RemoveDialogComponent,
    QuestionnaireCreateComponent,
    QuestionnaireEditComponent,
    QuestionAddComponent,
    UserOverviewComponent,
    QuestionViewComponent,
    QuestionnaireFinishComponent,
    ResultViewComponent,
    StatementViewComponent,
    StatementEditComponent,
    StatementCreateComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatExpansionModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatListModule,
    MatProgressBarModule,
    MatTableModule,

    MarkdownModule.forRoot()
  ],
  entryComponents: [
    CredentialsDialogComponent,
    RemoveDialogComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
