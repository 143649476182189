import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { UserModel, UserQuestionnaireStatus } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from 'src/app/models/user-role';
import { MatDialog } from '@angular/material/dialog';
import { RemoveDialogComponent } from '../remove-dialog/remove-dialog.component';
import { QuestionnaireModel } from 'src/app/models/questionnaire.model';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { AnswerService } from 'src/app/services/answer.service';
import { StatementService } from 'src/app/services/statement.service';
import { StatementModel } from 'src/app/models/statement.model';

@Component({
  selector: 'app-admin-overview',
  templateUrl: './admin-overview.component.html',
  styleUrls: ['./admin-overview.component.scss']
})
export class AdminOverviewComponent implements OnInit {

  private users: UserModel[];
  private questionnaires: QuestionnaireModel[];
  private statements: StatementModel[];
  private userMessage: string;
  private userErrorMessage: string;
  UserRole = UserRole;

  constructor(private authService: AuthService, private answerService: AnswerService, private userService: UserService, public dialog: MatDialog, private questionnaireService: QuestionnaireService, private statementService: StatementService) { }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    console.info("refresh")
    this.userService.getUsers().then(users => this.users = users).catch(e => {
      console.error(e);
    });
    this.questionnaireService.getQuestionnaires().then(questionnaires => this.questionnaires = questionnaires).catch(e => {
      console.error(e);
    });
    this.statementService.getStatements().then(statements => this.statements = statements).catch(e => {
      console.error(e);
    });
  }

  getUserOverviewDescription(users: UserModel[]): string {
    let parts: Array<{description: string, count: number}> = [];
    users.forEach(user => {
      let description = this.getUserDescription(user);
      description.split(", ").filter(part => part).forEach(part => {
        let existingPart = parts.find(p => p.description === part);
        if (existingPart) {
          existingPart.count++;
        } else {
          parts.push({
            description: part,
            count: 1
          });
        }
      });
    })

    return parts.map(p => `${p.count} ${p.description}`).join(", ");
  }

  getUserDescription(user: UserModel): string {
    let parts = [];
    if (user.userId === this.authService.getUserDetails().userId) {
      parts.push("admin");
    }
    if (user.role === UserRole.USER) {
      if (user.questionnaires) {
        if (user.questionnaires.find(q => q.status === UserQuestionnaireStatus.IN_PROGRESS)) {
          parts.push("bezig");
        } else if (user.questionnaires.find(q => q.status === UserQuestionnaireStatus.SENDED)) {
          parts.push("afgerond");
        }
      }
    }
    return parts.join(", ");
  }

  removeUser(user: UserModel): void {
    const dialogRef = this.dialog.open(RemoveDialogComponent, {
      width: "400px",
      data: { type: "gebruiker", name: user.email }
    });
    dialogRef.afterClosed().subscribe(removed => {
      if (removed) {
        this.userService.removeUser(user).then(() => {
          this.refresh();
        }).catch(e => {
          console.error(e);
        })
      }
    });
  }

  removeQuestionnaire(questionnaire: QuestionnaireModel): void {
    const dialogRef = this.dialog.open(RemoveDialogComponent, {
      width: "400px",
      data: { type: "vragenlijst", name: questionnaire.name }
    });
    dialogRef.afterClosed().subscribe(removed => {
      if (removed) {
        this.questionnaireService.removeQuestionnaire(questionnaire.questionnaireId).then(() => {
          this.refresh();
        }).catch(e => {
          console.error(e);
        })
      }
    });
  }

  getQuestionnaire(questionnaireId: string): QuestionnaireModel {
    return this.questionnaires.find(q => q.questionnaireId === questionnaireId);
  }

  resetPassword(user: UserModel) {
    this.userService.resetUserPassword(user.userId).then(() => {
      this.userMessage = "Wachtwoord reset email is verstuurd."
    }).catch(e => {
      console.error(e);
      this.userErrorMessage = "Er is een fout opgetreden.";
    });
  }

  removeAnswers(user: UserModel, questionnaireId: string) {
    const dialogRef = this.dialog.open(RemoveDialogComponent, {
      width: "400px",
      data: { type: "antwoorden vragenlijst", name: this.questionnaires.find(q => q.questionnaireId === questionnaireId).name }
    });
    dialogRef.afterClosed().subscribe(removed => {
      if (removed) {
        this.answerService.removeUserAnswers(user.userId, questionnaireId).then(() => {
          this.refresh();
        }).catch(e => {
          console.error(e);
        });
      }
    });
  }

  removeStatement(statement: StatementModel): void {
    const dialogRef = this.dialog.open(RemoveDialogComponent, {
      width: "400px",
      data: { type: "mededeling", name: statement.name }
    });
    dialogRef.afterClosed().subscribe(removed => {
      if (removed) {
        this.statementService.removeStatement(statement.statementId).then(() => {
          this.refresh();
        }).catch(e => {
          console.error(e);
        })
      }
    });
  }

}

