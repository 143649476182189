import { Injectable } from '@angular/core';
import { AnswerModel, AttachmentDetailsModel } from '../models/answer.model';
import { AuthService } from './auth.service';
import { AnswerClient } from '../clients/answer.client';
import { encrypt, decrypt } from '../helpers/crypto';
import * as streamSaver from "streamsaver";
import { QuestionModel } from '../models/question.model';
import { QuestionnaireModel } from '../models/questionnaire.model';
import { QuestionnaireService } from './questionnaire.service';
import { KeyService } from './key.service';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: "root"
})
export class AnswerService {

  private cachedAnswers: {
    [questionnaireId: string]: AnswerModel[]
  } = {};

  constructor(private answerClient: AnswerClient, private keyService: KeyService, private authService: AuthService, private questionnaireService: QuestionnaireService) {

  }

  public async getAnswer(questionnaireId: string, questionId: string, cached = true): Promise<AnswerModel> {
    let answers = await this.getAnswers(questionnaireId, cached);
    return answers.find(answer => answer.questionId === questionId);
  }

  public async getAnswers(questionnaireId: string, cached = true): Promise<AnswerModel[]> {
    if (cached && this.cachedAnswers[questionnaireId]) {
      return this.cachedAnswers[questionnaireId];
    }

    let credentials = await this.authService.getCredentials();
    let key = await this.keyService.getUserKey(credentials.password);

    let answers = await this.answerClient.getAnswers(this.authService.getUserDetails().userId, questionnaireId);
    for (let answer of answers) {
      answer.value = answer.value && (await decrypt(key, answer.value, "utf8")) as string;
    }
    this.cachedAnswers[questionnaireId] = answers;
    return answers;
  }

  public async getUserAnswers(questionnaireId: string, userId: string): Promise<AnswerModel[]> {
    let credentials = await this.authService.getCredentials();
    let userDetails = await this.authService.getUserDetails();

    console.info("get shared admin key")
    let sharedAdminKey = await this.keyService.getSharedAdminKey(userDetails.userId, credentials.password);

    console.info("get admin key")
    let adminKey = await this.keyService.getUserAdminKey(userId, sharedAdminKey);

    console.info("get ansers")
    let answers = await this.answerClient.getAnswers(userId, questionnaireId);
    for (let answer of answers) {
      console.info("decrypt: ", answer.value, adminKey)
      if (answer.value) {
        answer.value = (await decrypt(adminKey, answer.value, "utf8")) as string;
      }
    }
    console.info("done")
    return answers;
  }

  public async createAnswer(questionnaireId: string, questionId: string, value: string): Promise<AnswerModel> {
    let credentials = await this.authService.getCredentials();
    let userKey = await this.keyService.getUserKey(credentials.password);

    let result = await this.answerClient.createAnswer(this.authService.getUserDetails().userId, questionnaireId, {
      questionId: questionId,
      value: (await encrypt(userKey, value)) as string
    });
    result.value = value;
    if (this.cachedAnswers[questionnaireId]) {
      this.cachedAnswers[questionnaireId].push(result);
    }
    return result;
  }

  public async updateAnswer(questionnaireId: string, answerModel: AnswerModel): Promise<AnswerModel> {
    let credentials = await this.authService.getCredentials();
    let userKey = await this.keyService.getUserKey(credentials.password);

    let result = await this.answerClient.updateAnswer(this.authService.getUserDetails().userId, questionnaireId, answerModel.answerId, {
      questionId: answerModel.questionId,
      value: (await encrypt(userKey, answerModel.value)) as string
    });
    if (this.cachedAnswers[questionnaireId]) {
      let item = this.cachedAnswers[questionnaireId].find(a => a.answerId === answerModel.answerId);
      item.value = answerModel.value;
    }
    return result;
  }

  public async uploadAttachment(questionnaireId: string, answerId: string, file: File, progressCallback: (progress: number) => void): Promise<AttachmentDetailsModel> {
    let credentials = await this.authService.getCredentials();
    let userKey = await this.keyService.getUserKey(credentials.password);

    let currentChunk = 0;
    let chunkSize = 1 * 1024 * 1024; // 1 MB
    let chunks = Math.ceil(file.size / chunkSize);

    // Create attachment
    let attachmentDetails = await this.answerClient.createAttachment(this.authService.getUserDetails().userId, questionnaireId, answerId, {
      name: file.name,
      contentType: file.type,
      chunks: chunks,
      size: file.size
    });

    // Upload chunks
    await new Promise<void>((resolve, reject) => {

      let fileReader = new FileReader();
      fileReader.onerror = () => {
        reject(fileReader.error);
      };

      fileReader.onload = () => {
        let data = new Uint8Array(fileReader.result as ArrayBuffer);
        encrypt(userKey, data, "hex").then((encryptedData: string) => {
          return this.answerClient.uploadAttachmentChunk(this.authService.getUserDetails().userId, questionnaireId, answerId, attachmentDetails.attachmentId, currentChunk, encryptedData);
        }).then(() => {
          currentChunk++;
          progressCallback(currentChunk / chunks * 100);
          seek();
        }).catch(e => {
          reject(e);
        });
      };

      seek();

      function seek() {
        let offset = currentChunk * chunkSize;
        if (offset >= file.size) {
          resolve();
        } else {
          let slice = file.slice(offset, offset + chunkSize);
          fileReader.readAsArrayBuffer(slice);
        }
      }

    }).catch(e => {
      // Delete attachment if upload fails
      this.answerClient.removeAttachment(this.authService.getUserDetails().userId, questionnaireId, answerId, attachmentDetails.attachmentId).catch(ee => {
        console.error(ee);
      });
      throw e;
    });

    return attachmentDetails;
  }

  public removeAttachment(questionnaireId: string, answerId: string, attachment: AttachmentDetailsModel): Promise<void> {
    return this.answerClient.removeAttachment(this.authService.getUserDetails().userId, questionnaireId, answerId, attachment.attachmentId);
  }

  public async downloadUserAttachment(questionnaireId: string, answerId: string, attachment: AttachmentDetailsModel): Promise<void> {
    let credentials = await this.authService.getCredentials();
    let userKey = await this.keyService.getUserKey(credentials.password);

    return this.downloadAttachment(questionnaireId, answerId, attachment, this.authService.getUserDetails().userId, userKey);
  }

  public async downloadAdminRawAttachment(questionnaireId: string, answerId: string, attachment: AttachmentDetailsModel, userId: string): Promise<Uint8Array> {
    let credentials = await this.authService.getCredentials();
    let userDetails = await this.authService.getUserDetails();

    let sharedAdminKey = await this.keyService.getSharedAdminKey(userDetails.userId, credentials.password)
    let userKey = await this.keyService.getUserAdminKey(userId, sharedAdminKey);

    let parts: Uint8Array[] = [];
    for(let chunk = 0; chunk < attachment.chunks; chunk++) {
      let chunkData = await this.answerClient.downloadAttachmentChunk(userId, questionnaireId, answerId, attachment.attachmentId, chunk);
      parts.push(await decrypt(userKey, chunkData.data, "bin") as Uint8Array);
    }
    
    return new Uint8Array(parts.reduce((acc, curr) => [...acc, ...curr], []));
  }


  public async downloadAdminAttachment(questionnaireId: string, answerId: string, attachment: AttachmentDetailsModel, userId: string): Promise<void> {
    let credentials = await this.authService.getCredentials();
    let userDetails = await this.authService.getUserDetails();

    let sharedAdminKey = await this.keyService.getSharedAdminKey(userDetails.userId, credentials.password)
    let userKey = await this.keyService.getUserAdminKey(userId, sharedAdminKey);

    return this.downloadAttachment(questionnaireId, answerId, attachment, userId, userKey);
  }

  private async downloadAttachment(questionnaireId: string, answerId: string, attachment: AttachmentDetailsModel, userId: string, key: string): Promise<void> {
    let fileStream = streamSaver.createWriteStream(attachment.name, {
      size: attachment.size, // (optional) Will show progress
      writableStrategy: undefined, // (optional)
      readableStrategy: undefined  // (optional)
    });

    let fileWriter = fileStream.getWriter();

    for(let chunk = 0; chunk < attachment.chunks; chunk++) {
      let chunkData = await this.answerClient.downloadAttachmentChunk(userId, questionnaireId, answerId, attachment.attachmentId, chunk);
      await fileWriter.write(await decrypt(key, chunkData.data, "bin"));
    }

    fileWriter.close();
  }

  public getQuestionChain(questionnaire: QuestionnaireModel): Promise<QuestionModel[]> {
    return this.getAnswers(questionnaire.questionnaireId).then(answers => {
      let questionChain = [];

      for (let i = 0; i < questionnaire.questions.length; i++) {
        let question = questionnaire.questions[i];
        questionChain.push(question);
        let answer = answers.find(a => a.questionId === question._id);
        if (question.options && answer && answer.value) {
          let selectedOption = question.options.find(o => o.name === answer.value);
          if (selectedOption && selectedOption.followUpQuestionId) {
            // jump to follow up question
            i = questionnaire.questions.findIndex(q => q._id === selectedOption.followUpQuestionId) - 1;
          }
        }
      }

      return questionChain;
    });
  }

  public async submitQuestionnaire(questionnaireId: string): Promise<void> {
    await this.answerClient.sendQuestionnaire(this.authService.getUserDetails().userId, questionnaireId);
    this.questionnaireService.clearCache();
  }

  public async removeAnswers(questionnaireId: string): Promise<void> {
    await this.answerClient.removeAnswers(this.authService.getUserDetails().userId, questionnaireId);
    this.questionnaireService.clearCache();
  }

  public async removeUserAnswers(userId: string, questionnaireId: string): Promise<void> {
    await this.answerClient.removeAnswers(userId, questionnaireId);
    this.questionnaireService.clearCache();
  }
}
