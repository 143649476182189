import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StatementService } from 'src/app/services/statement.service';
import { StatementModel } from 'src/app/models/statement.model';

@Component({
  selector: 'app-statement-edit',
  templateUrl: './statement-edit.component.html',
  styleUrls: ['./statement-edit.component.scss']
})
export class StatementEditComponent implements OnInit {

  statement: StatementModel;
  loading = false;
  errorMessage = '';

  constructor(private route: ActivatedRoute, private statementService: StatementService, private router: Router) { }

  ngOnInit() {
    let statementId = this.route.snapshot.params.statementId;
    this.statementService.getStatement(statementId).then(statement => {
      this.statement = statement;
    }).catch(e => {
      console.error(e);
    });
  }

  public saveStatement() {
    this.loading = true;
    this.statementService.updateStatement(this.statement).then(() => {
      this.router.navigate(["/home"]);
    }).catch(e => {
      this.errorMessage = "Er is een fout opgetreden.";
    }).then(() => {
      this.loading = false;
    })
  }

}
