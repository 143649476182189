import { Component, OnInit } from '@angular/core';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionnaireModel } from 'src/app/models/questionnaire.model';
import { AnswerService } from 'src/app/services/answer.service';
import { UserQuestionnaireStatus } from 'src/app/models/user.model';

@Component({
  selector: 'app-questionnaire-finish',
  templateUrl: './questionnaire-finish.component.html',
  styleUrls: ['./questionnaire-finish.component.scss']
})
export class QuestionnaireFinishComponent implements OnInit {

  questionnaireId: string;
  questionnaire: QuestionnaireModel;
  isSubmitted = false;

  errorMessage: string;
  loading = false;

  constructor(private questionnaireService: QuestionnaireService, private answerService: AnswerService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      this.questionnaireId = routeParams.questionnaireId;

      // get question
      this.questionnaireService.getQuestionnaire(this.questionnaireId, true).then(questionnaire => {
        this.questionnaire = questionnaire;
        this.isSubmitted = questionnaire.status === UserQuestionnaireStatus.SENDED;
        console.info(this.isSubmitted, questionnaire.status)
      }).catch(e => {
        console.error(e);
        this.errorMessage = "Er is een fout opgetreden.";
      });
    });
  }

  public back() {
    this.loading = true;
    this.errorMessage = '';
    this.answerService.getQuestionChain(this.questionnaire).then(questionChain => {
      let index = questionChain.length;
      if (index <= 0) {
        // return to home
        this.router.navigate(["/home"]);
      } else {
        let previousQuestion = questionChain[index - 1];
        this.router.navigate(["/questions", this.questionnaireId, previousQuestion._id]);
      }
    }).catch(e => {
      console.error(e);
      this.errorMessage = 'Er is een fout opgetreden.'
    }).then(() => {
      this.loading = false;
    });
  }

  submit() {
    this.loading = true;
    this.answerService.submitQuestionnaire(this.questionnaireId).then(() => {
      this.router.navigate(["/home"]);
    }).catch(e => {
      console.error(e);
      this.errorMessage = "Er is een fout opgetreden."
    }).then(() => {
      this.loading = false;
    });
  }

}
