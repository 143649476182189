import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AnswerModel, AttachmentDetailsModel } from '../models/answer.model';
import { AnswerRequestModel } from '../models/answer-request.model';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: "root"
})
export class AnswerClient {

  constructor(private http: HttpClient, private authService: AuthService) {

  }

  public getAnswers(userId: string, questionnaireId: string): Promise<AnswerModel[]> {
    return this.http.get<AnswerModel[]>(`/api/v1/users/${userId}/questionnaires/${questionnaireId}/answers`).toPromise();
  }

  public getAnswer(userId: string, questionnaireId: string, answerId: string): Promise<AnswerModel> {
    return this.http.get<AnswerModel>(`/api/v1/users/${userId}/questionnaires/${questionnaireId}/answers/${answerId}`).toPromise();
  }

  public createAnswer(userId: string, questionnaireId: string, answerRequest: AnswerRequestModel): Promise<AnswerModel> {
    return this.http.post<AnswerModel>(`/api/v1/users/${userId}/questionnaires/${questionnaireId}/answers`, answerRequest).toPromise();
  }

  public updateAnswer(userId: string, questionnaireId: string, answerId: string, answerRequest: AnswerRequestModel): Promise<AnswerModel> {
    return this.http.put<AnswerModel>(`/api/v1/users/${userId}/questionnaires/${questionnaireId}/answers/${answerId}`, answerRequest).toPromise();
  }

  public async createAttachment(userId: string, questionnaireId: string, answerId: string, attachment: AttachmentDetailsModel): Promise<AttachmentDetailsModel> {
    return this.http.post<AttachmentDetailsModel>(`/api/v1/users/${userId}/questionnaires/${questionnaireId}/answers/${answerId}/attachments`, attachment).toPromise();
  }

  public async removeAttachment(userId: string, questionnaireId: string, answerId: string, attachmentId: string): Promise<void> {
    return this.http.delete<void>(`/api/v1/users/${userId}/questionnaires/${questionnaireId}/answers/${answerId}/attachments/${attachmentId}`).toPromise();
  }

  public async uploadAttachmentChunk(userId: string, questionnaireId: string, answerId: string, attachmentId: string, chunkIndex: number, data: string): Promise<void> {
    return this.http.put<void>(`/api/v1/users/${userId}/questionnaires/${questionnaireId}/answers/${answerId}/attachments/${attachmentId}/chunks/${chunkIndex}`, {
      data: data
    }).toPromise();
  }

  public async downloadAttachmentChunk(userId: string, questionnaireId: string, answerId: string, attachmentId: string, chunkIndex: number): Promise<{data: string}> {
    return this.http.get<{data: string}>(`/api/v1/users/${userId}/questionnaires/${questionnaireId}/answers/${answerId}/attachments/${attachmentId}/chunks/${chunkIndex}`).toPromise();
  }

  public async sendQuestionnaire(userId: string, questionnaireId: string): Promise<void> {
    return this.http.post<void>(`/api/v1/users/${userId}/questionnaires/${questionnaireId}/send`, {}).toPromise();
  }

  public async removeAnswers(userId: string, questionnaireId: string): Promise<void> {
    return this.http.delete<void>(`/api/v1/users/${userId}/questionnaires/${questionnaireId}/answers`, {}).toPromise();
  }

}