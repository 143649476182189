import { Injectable } from '@angular/core';
import { decrypt } from '../helpers/crypto';
import { UserClient } from '../clients/user.client';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: "root"
})
export class KeyService {

  public key: string;
  private sharedAdminKey: string;
  private adminKeys: {
    [userId: string]: string
  } = {};

  constructor(private userClient: UserClient) {}

  public async tryPassword(password: string): Promise<void> {
    if (!this.key) {
      throw new Error(`Key is missing`);
    }
    return decrypt(password, this.key).then(() => {});
  }

  public async getUserKey(password: string): Promise<string> {
    if (!this.key) {
      throw new Error(`Key is missing`);
    }
    return (await decrypt(password, this.key, "hex")) as string;
  }

  public async getUserAdminKey(userId: string, sharedAdminKey: string): Promise<string> {
    if (this.adminKeys[userId]) {
      return this.adminKeys[userId];
    }

    let adminKeyData = await this.userClient.getUserAdminKey(userId);

    let adminKey = await decrypt(sharedAdminKey, adminKeyData.key, "hex") as string;
    this.adminKeys[userId] = adminKey;
    return adminKey;
  }

  public async getSharedAdminKey(userId: string, password: string): Promise<string> {
    if (this.sharedAdminKey) {
      return this.sharedAdminKey;
    }

    let sharedAdminKeyData = await this.userClient.getSharedAdminKey(userId);

    let sharedAdminKey = await decrypt(password, sharedAdminKeyData.sharedAdminKey, "hex") as string;
    this.sharedAdminKey = sharedAdminKey;
    return sharedAdminKey;
  }

}