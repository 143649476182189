import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { sha256 } from 'src/app/helpers/crypto';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  title: string;

  signupForm: FormGroup;
  validRequest: string;
  showPassword = false;
  errorMessage: string = "";
  loading: boolean;
  invitationToken: string;
  userId: string;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private userService: UserService, private router: Router) { }

  ngOnInit() {
    console.info(this.route.snapshot.url[0].path)
    this.title = this.route.snapshot.url[0].path === "change-password" ? "Wachtwoord veranderen" : "Aanmelden";

    this.invitationToken = this.route.snapshot.queryParams.invite;
    let email = this.route.snapshot.queryParams.email;
    this.userId = this.route.snapshot.queryParams.userId;

    this.validRequest = this.invitationToken && email && this.userId;
    this.signupForm = this.formBuilder.group({
      email: [email, Validators.required],
      password: ['', Validators.compose([Validators.required, this.strongPasswordValidator, Validators.minLength(10)])],
      confirmPassword: ['', Validators.compose([Validators.required])],
    }, {validator: this.checkPasswords });
  }

  public checkPasswords(group: FormGroup) {
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;

    return confirmPass.length === 0 || pass === confirmPass ? null : { notSame: true }
  }

  public setGeneratedPassword() {
    let value = this.signupForm.value;
    value.password = this.generatePassword();
    this.signupForm.setValue(value);
    this.showPassword = true;
  }

  private generatePassword() {
    var length = 20;
    var string = 'abcdefghijklmnopqrstuvwxyz'; //to upper
    var numeric = '0123456789';
    var punctuation = '!@#$%^&*()_+~`|}{[]\:;?><,./-=';
    var password = '';
    var character = '';
    while (password.length < length) {
        let entity1 = Math.ceil(string.length * Math.random()*Math.random());
        let entity2 = Math.ceil(numeric.length * Math.random()*Math.random());
        let entity3 = Math.ceil(punctuation.length * Math.random()*Math.random());
        let hold = string.charAt( entity1 );
        hold = (password.length%2==0)?(hold.toUpperCase()):(hold);
        character += hold;
        character += numeric.charAt( entity2 );
        character += punctuation.charAt( entity3 );
        password = character;
    }
    password = password.split('').sort(() => 0.5 - Math.random()).join('');
    return password.substr(0, length);
  }

  public strongPasswordValidator(control: FormControl): ValidationErrors {
    let hasNumber = /\d/.test(control.value);
    let hasUpper = /[A-Z]/.test(control.value);
    let hasLower = /[a-z]/.test(control.value);
    let hasSpecial = /[!@#$%^&*()_+~`|}{\[\]\\:;?><,.\/\-=]/.test(control.value);
    // console.log('Num, Upp, Low', hasNumber, hasUpper, hasLower);
    const valid = hasNumber && hasUpper && hasLower && hasSpecial;
    if (!valid) {
        // return what´s not valid
        return { strong: true };
    }
    return null;
  }

  public doSignup(signupData) {
    this.loading = true;
    this.errorMessage = '';
    this.signupForm.disable();
    sha256(signupData.password).then(password => {
      return this.userService.updatePassword(this.userId, this.invitationToken, password);
    }).then(() => {
      this.signupForm.reset();
      this.router.navigate(["login"]);
    }).catch(e => {
      this.errorMessage = 'Er is een fout opgetreden';
    }).then(() => {
      this.loading = false;
      this.signupForm.enable();
    });
  }

}
