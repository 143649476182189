import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UserDetails } from 'src/app/models/user-details';
import { QuestionnaireModel } from 'src/app/models/questionnaire.model';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { UserService } from 'src/app/services/user.service';
import { UserModel } from 'src/app/models/user.model';
import { AnswerService } from 'src/app/services/answer.service';
import { MatDialog } from '@angular/material/dialog';
import { RemoveDialogComponent } from '../remove-dialog/remove-dialog.component';

@Component({
  selector: 'app-user-overview',
  templateUrl: './user-overview.component.html',
  styleUrls: ['./user-overview.component.scss']
})
export class UserOverviewComponent implements OnInit {

  private user: UserModel;
  private questionnaires: {
    [id: string]: QuestionnaireModel
  } = {};

  constructor(private authService: AuthService, private questionnaireService: QuestionnaireService, private answerService: AnswerService, private userService: UserService, public dialog: MatDialog) { }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.questionnaires = {};
    this.userService.getCurrentUser().then(user => {
      this.user = user;
      user.questionnaires.forEach(userQuestionnaire => {
        this.questionnaireService.getQuestionnaire(userQuestionnaire.questionnaireId, true).then(questionnaire => {
          this.questionnaires[userQuestionnaire.questionnaireId] = questionnaire;
        }).catch(e => {
          console.error(e);
        });
      })
    }).catch(e => {
      console.error(e);
    })
  }

  removeQuestionnaireAnswers(questionnaireId: string) {
    const dialogRef = this.dialog.open(RemoveDialogComponent, {
      width: "400px",
      data: { type: "vragenlijst", name: this.questionnaires[questionnaireId].name }
    });
    dialogRef.afterClosed().subscribe(removed => {
      if (removed) {
        this.answerService.removeAnswers(questionnaireId).then(() => {
          this.refresh();
        }).catch(e => {
          console.error(e);
        })
      }
    });


  }

}
