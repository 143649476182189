import { Injectable } from '@angular/core';
import { sha256, decrypt } from '../helpers/crypto';
import { AuthClient } from '../clients/auth.client';
import * as jwtDecode from "jwt-decode";
import { UserDetails } from '../models/user-details';
import { UserRole } from '../models/user-role';
import { Router } from '@angular/router';
import { UserCredentialsModel } from '../models/user-credentials.model';
import { MatDialog } from '@angular/material/dialog';
import { resolve } from 'url';
import { CredentialsDialogComponent } from '../components/credentials-dialog/credentials-dialog.component';
import { KeyService } from './key.service';
import { UserClient } from '../clients/user.client';

const HOUR = 60 * 60 * 1000;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token: string;
  private userDetails: UserDetails;
  private credentials: UserCredentialsModel;

  constructor(private authClient: AuthClient, private router: Router, public dialog: MatDialog, private keyService: KeyService) {
    let tokenResponse = JSON.parse(localStorage.getItem("token"));
    if (tokenResponse) {
      this.loadToken(tokenResponse.token);
      this.keyService.key = tokenResponse.key;
    }
  }

  public async login(username: string, password: string): Promise<void> {
    let credentials = {
      username: username,
      password: await sha256(password)
    };

    let tokenResponse = await this.authClient.login(credentials);
    localStorage.setItem("token", JSON.stringify(tokenResponse));
    if (this.loadToken(tokenResponse.token)) {
      this.keyService.key = tokenResponse.key;
      this.setCredentials(credentials);
    }
  }

  public logout(): void {
    this.authClient.logout()
    .catch(e => console.error(e))
    .then(() => {
      this.token = undefined;
      this.userDetails = undefined;
      localStorage.removeItem("token");
      this.router.navigate(["login"]);
    });
  }

  public hasRole(role: UserRole): boolean {
    return this.userDetails && this.userDetails.role === role;
  }

  public isAuthenticated(): boolean {
    return this.token && this.userDetails.exp * 1000 >= Date.now();
  }

  public getToken(): string {
    return this.token;
  }

  public getUserDetails(): UserDetails {
    return this.userDetails;
  }

  public async getCredentials(): Promise<UserCredentialsModel|undefined> {
    if (this.credentials) {
      return this.credentials;
    } else {
      const dialogRef = this.dialog.open(CredentialsDialogComponent, {
        width: '500px',
        data: {
          username: this.userDetails.email,
          password: ''
        }
      });

      return new Promise((resolve, reject) => {
        dialogRef.afterClosed().subscribe(newCredentials => {
          console.log(newCredentials);
          if (newCredentials === "Key is missing") {
            this.logout();
            resolve(undefined);
          } else if (newCredentials) {
            this.setCredentials(newCredentials);
            resolve(newCredentials);
          } else {
            resolve(undefined);
          }
        });
      });
    }
  }

  private loadToken(token: string): boolean {
    let decode = jwtDecode(token);
    if (!decode.exp || decode.exp * 1000 < Date.now()) {
      console.error(`Token has expired`);
      return false;
    } else {
      this.token = token;
      this.userDetails = decode;
      return true;
    }
  }

  private setCredentials(credentials: UserCredentialsModel) {
    this.credentials = credentials;
    setTimeout(() => {
      this.credentials = undefined;
    }, 1 * HOUR);
  }

}
