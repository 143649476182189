
export interface QuestionModel {

  _id?: string;
  name: string;
  description?: string;
  hasAttachments?: boolean;
  type: QuestionTypeModel;
  placeholder?: string;
  options?: QuestionOption[];

}

export interface QuestionOption {

  name?: string;
  description?: string;
  followUpQuestionId?: string;

}

export enum QuestionTypeModel {
  TEXT_FIELD = "TEXT_FIELD",
  TEXT_AREA = "TEXT_AREA",
  CHECK_BOX = "CHECK_BOX",
  RADIO_BUTTON = "RADIO_BUTTON",
  DROP_DOWN = "DROP_DOWN"
}