import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { QuestionnaireModel } from 'src/app/models/questionnaire.model';
import { QuestionModel } from 'src/app/models/question.model';

@Component({
  selector: 'app-questionnaire-edit',
  templateUrl: './questionnaire-edit.component.html',
  styleUrls: ['./questionnaire-edit.component.scss']
})
export class QuestionnaireEditComponent implements OnInit {

  questionnaire: QuestionnaireModel;
  loading = false;
  errorMessage = '';

  constructor(private route: ActivatedRoute, private questionnaireService: QuestionnaireService, private router: Router) { }

  ngOnInit() {
    let questionnaireId = this.route.snapshot.params.questionnaireId;
    this.questionnaireService.getQuestionnaire(questionnaireId).then(questionnaire => {
      if (questionnaire.questions) {
        questionnaire.questions.map(question => {
          if (!question.placeholder) {
            question.placeholder = '';
          }
          if (!question.options) {
            question.options = [];
          }
        })
      } else {
        questionnaire.questions = [];
      }
      this.questionnaire = questionnaire;
    }).catch(e => {
      console.error(e);
    });
  }

  public saveQuestionnaire() {
    this.loading = true;
    console.info(this.questionnaire);
    this.questionnaireService.updateQuestionnaire(this.questionnaire).then(() => {
      this.router.navigate(["/home"]);
    }).catch(e => {
      this.errorMessage = "Er is een fout opgetreden.";
    }).then(() => {
      this.loading = false;
    })
  }

  public removeQuestion(question: QuestionModel) {
    let index = this.questionnaire.questions.indexOf(question);
    if (index > -1) {
      this.questionnaire.questions.splice(index, 1);
    }
  }

}
