import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StatementModel } from '../models/statement.model';

@Injectable({
  providedIn: "root"
})
export class StatementClient {

  constructor(private http: HttpClient) {

  }

  public getStatements(): Promise<StatementModel[]> {
    return this.http.get<StatementModel[]>("/api/v1/statements").toPromise();
  }

  public getStatement(statementId: string): Promise<StatementModel> {
    return this.http.get<StatementModel>(`/api/v1/statements/${statementId}`).toPromise();
  }

  public createStatement(statement: StatementModel): Promise<StatementModel> {
    return this.http.post<StatementModel>(`/api/v1/statements`, statement).toPromise();
  }

  public updateStatement(statement: StatementModel): Promise<StatementModel> {
    return this.http.put<StatementModel>(`/api/v1/statements/${statement.statementId}`, {
      name: statement.name,
      message: statement.message
    }).toPromise();
  }

  public removeStatement(statementId: string): Promise<void> {
    return this.http.delete(`/api/v1/statements/${statementId}`).toPromise().then();
  }

}