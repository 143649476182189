import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignupComponent } from './components/signup/signup.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { AuthGuardService } from './services/auth-guard.service';
import { QuestionnaireEditComponent } from './components/questionnaire-edit/questionnaire-edit.component';
import { QuestionViewComponent } from './components/question-view/question-view.component';
import { QuestionnaireFinishComponent } from './components/questionnaire-finish/questionnaire-finish.component';
import { ResultViewComponent } from './components/result-view/result-view.component';
import { StatementEditComponent } from './components/statement-edit/statement-edit.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [AuthGuardService] },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuardService] },
  { path: 'signup', component: SignupComponent, canActivate: [AuthGuardService] },
  { path: 'change-password', component: SignupComponent, canActivate: [AuthGuardService] },
  { path: 'questionnaires/:questionnaireId', component: QuestionnaireEditComponent, canActivate: [AuthGuardService] },
  { path: 'questions/:questionnaireId/finish', component: QuestionnaireFinishComponent, canActivate: [AuthGuardService] },
  { path: 'questions/:questionnaireId/:questionId', component: QuestionViewComponent, canActivate: [AuthGuardService] },
  { path: 'results/:userId/:questionnaireId', component: ResultViewComponent, canActivate: [AuthGuardService] },
  { path: 'statements/:statementId', component: StatementEditComponent, canActivate: [AuthGuardService] },
  { path: '**', redirectTo: "home" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
