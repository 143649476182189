import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: "root"
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (route.url[0] && (route.url[0].path === "login" || route.url[0].path === "signup" || route.url[0].path === "change-password")) {
      if (this.authService.isAuthenticated()) {
        this.router.navigate(['home']);
        return false;
      }
    } else {
      if (!this.authService.isAuthenticated()) {
        this.router.navigate(['login']);
        return false;
      }
    }
    return true;
  }


}