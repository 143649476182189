import { Injectable } from "@angular/core";
import { UserModel } from '../models/user.model';
import { UserClient } from '../clients/user.client';
import { UserCredentialsModel } from '../models/user-credentials.model';
import { QuestionnaireModel } from '../models/questionnaire.model';
import { AuthService } from './auth.service';
import { UserRole } from "../models/user-role";

@Injectable({
  providedIn: "root"
})
export class UserService {

  constructor(private userClient: UserClient, private authService: AuthService) {}

  public getUsers(): Promise<UserModel[]> {
    return this.userClient.getUsers();
  }

  public getUser(userId: string): Promise<UserModel> {
    return this.userClient.getUser(userId);
  }

  public getCurrentUser(): Promise<UserModel> {
    return this.userClient.getUser(this.authService.getUserDetails().userId);
  }

  public removeUser(user: UserModel): Promise<void> {
    return this.userClient.removeUser(user.userId);
  }

  public inviteUser(email: string, userRole: UserRole, questionnaires: string[], credentials: UserCredentialsModel): Promise<void> {
    return this.userClient.inviteUser({
      inviteEmail: email,
      role: userRole,
      questionnaires: questionnaires,
      adminUsername: credentials.username,
      adminPassword: credentials.password
    });
  }

  public updatePassword(userId: string, oldPassword: string, newPassword: string): Promise<void> {
    return this.userClient.updatePassword(userId, {
      oldPassword: oldPassword,
      newPassword: newPassword
    });
  }

  public async resetUserPassword(userId: string): Promise<void> {
    let credentials = await this.authService.getCredentials();
    await this.userClient.resetPassword(userId, {
      adminUsername: credentials.username,
      adminPassword: credentials.password
    });
  }

}
