import { Injectable } from '@angular/core';
import { StatementModel } from '../models/statement.model';
import { StatementClient } from '../clients/statement.client';

@Injectable({
  providedIn: "root"
})
export class StatementService {

  private statementCache: StatementModel[];

  constructor(private statementClient: StatementClient) {

  }

  public async getStatements(cached = false): Promise<StatementModel[]> {
    if (cached && this.statementCache) {
      return this.statementCache;
    }
    let statements = await this.statementClient.getStatements();
    this.statementCache = statements;
    return statements;
  }

  public async getStatement(statementId: string): Promise<StatementModel> {
    return this.statementClient.getStatement(statementId);
  }

  public async createStatement(statement: StatementModel): Promise<StatementModel> {
    let result = await this.statementClient.createStatement(statement);
    this.clearCache();
    return result;
  }

  public async updateStatement(statement: StatementModel): Promise<StatementModel> {
    let result = await this.statementClient.updateStatement(statement);
    this.clearCache();
    return result;
  }

  public async removeStatement(statementId: string): Promise<void> {
    let result = await this.statementClient.removeStatement(statementId);
    this.clearCache();
    return result;
  }

  public clearCache() {
    this.statementCache = undefined;
  }

}