import { Component, Input, Output, EventEmitter } from '@angular/core';
import { QuestionModel, QuestionTypeModel, QuestionOption } from 'src/app/models/question.model';
import { MatDialog } from '@angular/material/dialog';
import { RemoveDialogComponent } from '../remove-dialog/remove-dialog.component';

@Component({
  selector: 'app-question-add',
  templateUrl: './question-add.component.html',
  styleUrls: ['./question-add.component.scss']
})
export class QuestionAddComponent {

  @Input()
  question: QuestionModel = {
    name: '',
    type: QuestionTypeModel.TEXT_FIELD,
    description: '',
    placeholder: '',
    hasAttachments: false,
    options: []
  };

  @Input()
  questions: QuestionModel[];

  @Input()
  add: boolean = false;

  @Output()
  questionChanged = new EventEmitter<QuestionModel>();

  @Output()
  questionAdded = new EventEmitter<QuestionModel>();

  @Output()
  questionRemoved = new EventEmitter<QuestionModel>();

  QuestionTypeModel = QuestionTypeModel;

  types = [
    QuestionTypeModel.TEXT_FIELD,
    QuestionTypeModel.TEXT_AREA,
    QuestionTypeModel.CHECK_BOX,
    QuestionTypeModel.RADIO_BUTTON,
    QuestionTypeModel.DROP_DOWN
  ]

  typeNames = {
    [QuestionTypeModel.TEXT_FIELD]: "Textfield",
    [QuestionTypeModel.TEXT_AREA]: "Textarea",
    [QuestionTypeModel.CHECK_BOX]: "Checkbox",
    [QuestionTypeModel.RADIO_BUTTON]: "Radio button",
    [QuestionTypeModel.DROP_DOWN]: "Dropdown",
  }

  constructor(public dialog: MatDialog) { }

  emitChange() {
    this.questionChanged.emit(this.question);
  }

  addQuestion() {
    this.questionAdded.emit(this.question);
    this.question = {
      name: '',
      type: QuestionTypeModel.TEXT_FIELD,
      description: '',
      placeholder: '',
      hasAttachments: false,
      options: []
    };
  }

  removeQuestion() {
    const dialogRef = this.dialog.open(RemoveDialogComponent, {
      width: "400px",
      data: { type: "vraag", name: this.question.name }
    });
    dialogRef.afterClosed().subscribe(removed => {
      if (removed) {
        this.questionRemoved.emit(this.question);
      }
    });
  }

  addOption() {
    this.question.options.push({
      name: '',
      description: '',
      followUpQuestionId: ''
    });
  }

  removeOption(option: QuestionOption) {
    let index = this.question.options.indexOf(option);
    if (index > -1) {
      this.question.options.splice(index, 1);
    }
  }

  currentIndex(question: QuestionModel, questions: QuestionModel[]) {
    let index = questions.indexOf(question);
    console.info(index);
    return index;
  }
}
