import { Component, OnInit } from '@angular/core';
import { StatementService } from 'src/app/services/statement.service';
import { StatementModel } from 'src/app/models/statement.model';

@Component({
  selector: 'app-statement-view',
  templateUrl: './statement-view.component.html',
  styleUrls: ['./statement-view.component.scss']
})
export class StatementViewComponent implements OnInit {

  statements: StatementModel[];

  constructor(private statementService: StatementService) { }

  ngOnInit() {
    this.statementService.getStatements().then(statements => {
      this.statements = statements;
    }).catch(e => {
      console.error(e);
    });
  }

}
