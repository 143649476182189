import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UserCredentialsModel } from '../models/user-credentials.model';
import { UserTokenResponseModel } from '../models/user-token-response.model';

@Injectable({
  providedIn: 'root'
})
export class AuthClient {

  constructor(private http: HttpClient) {

  }

  public async login(credentials: UserCredentialsModel): Promise<UserTokenResponseModel | null> {
    try {
      return await this.http.post<UserTokenResponseModel>("/api/v1/login", credentials).toPromise();
    } catch(e) {
      if (e instanceof HttpErrorResponse) {
        if (e.status === 401) {
          throw new Error(`Email of wachtwoord is incorrect.`);
        } else if (e.status === 429) {
          throw new Error(`Account is gelocked voor 5 minuten.`);
        }
      }
      throw new Error(`Er is een fout opgetreden.`);
    }
  }

  public async logout(): Promise<void> {
    await this.http.post<UserTokenResponseModel>("/api/v1/logout", {}).toPromise();
  }


}