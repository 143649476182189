import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { StatementService } from 'src/app/services/statement.service';

@Component({
  selector: 'app-statement-create',
  templateUrl: './statement-create.component.html',
  styleUrls: ['./statement-create.component.scss']
})
export class StatementCreateComponent implements OnInit {

  createForm: FormGroup;
  errorMessage: string = "";
  loading: boolean;
  @Output()
  statementCreated = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private statementService: StatementService) { }

  ngOnInit() {
    this.createForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])]
    });
  }

  public createStatement(formValues: { name: string }) {
    this.createForm.disable();
    this.statementService.createStatement({
      name: formValues.name,
      message: ''
    }).then(result => {
      this.createForm.reset();
      console.info(`statement created`);
      this.statementCreated.emit();
    }).catch(e => {
      console.error(e);
      this.errorMessage = "Er is een fout opgetreden.";
    }).then(() => {
      this.createForm.enable();
      this.loading = false;
    })
  }
}
