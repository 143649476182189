import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QuestionnaireModel } from '../models/questionnaire.model';

@Injectable({
  providedIn: "root"
})
export class QuestionnaireClient {

  constructor(private http: HttpClient) {

  }

  public getQuestionnaires(): Promise<QuestionnaireModel[]> {
    return this.http.get<QuestionnaireModel[]>("/api/v1/questionnaires").toPromise();
  }

  public getQuestionnaire(questionnaireId: string): Promise<QuestionnaireModel> {
    return this.http.get<QuestionnaireModel>(`/api/v1/questionnaires/${questionnaireId}`).toPromise();
  }

  public createQuestionnaire(questionnaire: QuestionnaireModel): Promise<QuestionnaireModel> {
    return this.http.post<QuestionnaireModel>(`/api/v1/questionnaires`, questionnaire).toPromise();
  }

  public updateQuestionnaire(questionnaire: QuestionnaireModel): Promise<QuestionnaireModel> {
    return this.http.put<QuestionnaireModel>(`/api/v1/questionnaires/${questionnaire.questionnaireId}`, {
      name: questionnaire.name,
      questions: questionnaire.questions || []
    }).toPromise();
  }

  public removeQuestionnaire(questionnaireId: string): Promise<void> {
    return this.http.delete(`/api/v1/questionnaires/${questionnaireId}`).toPromise().then();
  }

}