import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { QuestionnaireModel } from 'src/app/models/questionnaire.model';
import { UserRole } from 'src/app/models/user-role';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

  inviteForm: FormGroup;
  errorMessage: string = "";
  loading: boolean;

  userRoles = Object.values(UserRole);
  @Input()
  questionnaires: QuestionnaireModel[];
  @Output()
  userInvited = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private userService: UserService) { }

  ngOnInit() {
    this.inviteForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      userRole: [UserRole.USER, Validators.required],
      questionnaire: ['']
    });
  }

  inviteUser(inviteData) {
    this.inviteForm.disable();
    this.authService.getCredentials().then(credentials => {
      if (credentials) {
        this.loading = true;
        let questionnaire = [];
        if (inviteData.questionnaire) {
          if (Array.isArray(inviteData.questionnaire)) {
            questionnaire = inviteData.questionnaire;
          } else {
            questionnaire = [inviteData.questionnaire];
          }
        }

        return this.userService.inviteUser(inviteData.email, inviteData.userRole, questionnaire, credentials).then(() => {
          this.inviteForm.reset();
          this.userInvited.emit();
        });
      }
    }).catch(e => {
      console.error(e);
      this.errorMessage = "Er is een fout opgetreden.";
    }).then(() => {
      this.inviteForm.enable();
      this.loading = false;
    })
  }

}
