import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserModel } from '../models/user.model';
import { UserInviteRequestModel } from '../models/user-invite-request.model';
import { UserUpdatePasswordRequestModel } from '../models/user-update-password-request.model';
import { AdminCredentialsModel } from '../models/admin-credentials.model';

@Injectable({
  providedIn: "root"
})
export class UserClient {

  constructor(private http: HttpClient) {

  }

  public getUsers(): Promise<UserModel[]> {
    return this.http.get<UserModel[]>("/api/v1/users").toPromise();
  }

  public getUser(userId: string): Promise<UserModel> {
    return this.http.get<UserModel>(`/api/v1/users/${userId}`).toPromise();
  }

  public inviteUser(invite: UserInviteRequestModel): Promise<void> {
    return this.http.post("/api/v1/users", invite).toPromise().then(() => {});
  }

  public removeUser(userId: string): Promise<void> {
    return this.http.delete(`/api/v1/users/${userId}`).toPromise().then(() => {});
  }

  public updatePassword(userId: string, request: UserUpdatePasswordRequestModel): Promise<void> {
    return this.http.put(`/api/v1/users/${userId}`, request).toPromise().then(() => {});
  }

  public resetPassword(userId: string, adminCredentials: AdminCredentialsModel): Promise<void> {
    return this.http.post(`/api/v1/users/${userId}/password-reset`, adminCredentials).toPromise().then(() => {});
  }

  public getUserAdminKey(userId: string): Promise<{ key: string}> {
    return this.http.get<{ key: string }>(`/api/v1/users/${userId}/admin-key`).toPromise();
  }

  public getSharedAdminKey(userId: string): Promise<{ sharedAdminKey: string}> {
    return this.http.get<{ sharedAdminKey: string }>(`/api/v1/users/${userId}/shared-admin-key`).toPromise();
  }
}