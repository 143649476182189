import { Injectable } from '@angular/core';
import { QuestionnaireClient } from '../clients/questionnaire.client';
import { QuestionnaireModel } from '../models/questionnaire.model';
import { QuestionModel } from '../models/question.model';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { UserRole } from '../models/user-role';

@Injectable({
  providedIn: "root"
})
export class QuestionnaireService {

  private questionnaireCache: {
    [id: string]: QuestionnaireModel
  } = {};

  constructor(private questionnaireClient: QuestionnaireClient, private userService: UserService, private authService: AuthService) {

  }

  public getQuestionnaires(): Promise<QuestionnaireModel[]> {
    return this.questionnaireClient.getQuestionnaires();
  }

  public async getQuestionnaire(questionnaireId: string, cached = false): Promise<QuestionnaireModel> {
    if (cached && this.questionnaireCache[questionnaireId]) {
      return this.questionnaireCache[questionnaireId];
    }
    let questionnaire = await this.questionnaireClient.getQuestionnaire(questionnaireId);

    if (this.authService.hasRole(UserRole.USER)) {
      let user = await this.userService.getCurrentUser();
      let userQuestionnaire = user.questionnaires && user.questionnaires.find(q => q.questionnaireId === questionnaireId);
      console.info(userQuestionnaire)
      if (userQuestionnaire) {
        questionnaire.status = userQuestionnaire.status;
      }
    }

    if (questionnaire) {
      this.questionnaireCache[questionnaireId] = questionnaire;
    }
    return questionnaire;
  }

  public createQuestionnaire(questionnaire: QuestionnaireModel): Promise<QuestionnaireModel> {
    return this.questionnaireClient.createQuestionnaire(questionnaire);
  }

  public updateQuestionnaire(questionnaire: QuestionnaireModel): Promise<QuestionnaireModel> {
    return this.questionnaireClient.updateQuestionnaire(questionnaire);
  }

  public removeQuestionnaire(questionnaireId: string): Promise<void> {
    return this.questionnaireClient.removeQuestionnaire(questionnaireId);
  }

  public clearCache() {
    this.questionnaireCache = {};
  }

}