import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { sha256 } from 'src/app/helpers/crypto';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { resolve } from 'url';
import { UserCredentialsModel } from 'src/app/models/user-credentials.model';
import { KeyService } from 'src/app/services/key.service';

@Component({
  selector: 'app-credentials-dialog',
  templateUrl: './credentials-dialog.component.html',
  styleUrls: ['./credentials-dialog.component.scss']
})
export class CredentialsDialogComponent implements OnInit {

  credentialsForm: FormGroup;
  errorMessage: string = "";
  loading: boolean;

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<CredentialsDialogComponent>, private keyService: KeyService,
    @Inject(MAT_DIALOG_DATA) public data: UserCredentialsModel) { }

  ngOnInit() {
    this.credentialsForm = this.formBuilder.group({
      username: [this.data.username, Validators.required],
      password: ['', Validators.required]
    });
  }

  public doSubmit(credentials) {
    this.errorMessage = '';
    this.loading = true;
    this.credentialsForm.disable();
    sha256(credentials.password).then(newPassword => {
      return this.keyService.tryPassword(newPassword).then(() => {
        let newCredentials = {
          username: credentials.username,
          password: newPassword
        };
        this.dialogRef.close(newCredentials);
      }).catch(ee => {
        if (ee.message === "Key is missing") {
          this.dialogRef.close("Key is missing");
        } else {
          this.errorMessage = `Wachtwoord is onjuist.`;
        }
      });
    }).catch(e => {
      console.error(e);
      this.errorMessage = "Er is een fout opgetreden.";
    }).then(() => {
      this.loading = false;
      this.credentialsForm.enable();
    });
  }

}
