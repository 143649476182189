import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';

@Component({
  selector: 'app-questionnaire-create',
  templateUrl: './questionnaire-create.component.html',
  styleUrls: ['./questionnaire-create.component.scss']
})
export class QuestionnaireCreateComponent implements OnInit {

  createForm: FormGroup;
  errorMessage: string = "";
  loading: boolean;
  @Output()
  questionnaireCreated = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private questionnaireService: QuestionnaireService) { }

  ngOnInit() {
    this.createForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])]
    });
  }

  public createQuestionnaire(formValues: { name: string }) {
    this.createForm.disable();
    this.questionnaireService.createQuestionnaire({
      name: formValues.name
    }).then(result => {
      this.createForm.reset();
      console.info(`questionnaire created`);
      this.questionnaireCreated.emit();
    }).catch(e => {
      console.error(e);
      this.errorMessage = "Er is een fout opgetreden.";
    }).then(() => {
      this.createForm.enable();
      this.loading = false;
    })
  }

}
