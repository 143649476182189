import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CredentialsDialogComponent } from '../credentials-dialog/credentials-dialog.component';
import { UserCredentialsModel } from 'src/app/models/user-credentials.model';

@Component({
  selector: 'app-remove-dialog',
  templateUrl: './remove-dialog.component.html',
  styleUrls: ['./remove-dialog.component.scss']
})
export class RemoveDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CredentialsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { type: string, name: string}) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
