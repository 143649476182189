import { binToHex, isHex, hexToBin } from './hex';

export async function sha256(data: string): Promise<string> {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(data);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert bytes to hex string
  return binToHex(new Uint8Array(hashBuffer));
}

export async function encrypt(password: string, plain: string | Uint8Array, outputFormat: "hex" | "bin" = "hex"): Promise<string | Uint8Array> {
  let buffer;
  if (typeof(plain) === "string") {
    buffer = new TextEncoder().encode(plain);
  } else {
    buffer = plain;
  }

  if (!isHex(password)) {
    throw new Error(`password is not hex`);
  }
  const passwordBuffer = hexToBin(password);

  let iv = window.crypto.getRandomValues(new Uint8Array(16));

  let key = await crypto.subtle.importKey(
    "raw",
    passwordBuffer,
    "AES-CBC",
    true,
    ["encrypt", "decrypt"]
  );

  let encryptedBuffer = await crypto.subtle.encrypt(
    {
      name: "AES-CBC",
      iv: iv
    },
    key,
    buffer
  );

  let encryptedBufferArray = new Uint8Array(encryptedBuffer);
  let array = new Uint8Array(iv.length + encryptedBufferArray.length);
  array.set(iv);
  array.set(encryptedBufferArray, iv.length);

  if (outputFormat === "bin") {
    return array;
  } else {
    return binToHex(array);
  }
}

export async function decrypt(password: string, encryptedData: string, outputFormat: "hex" | "bin" | "utf8" = "hex"): Promise<string | Uint8Array> {
  if (!isHex(encryptedData)) {
    throw new Error(`data is not hex`);
  }
  const msgBuffer = hexToBin(encryptedData);

  if (!isHex(password)) {
    throw new Error(`password is not hex`);
  }
  const passwordBuffer = hexToBin(password);

  let iv = msgBuffer.slice(0, 16);
  let buffer = msgBuffer.slice(16, msgBuffer.length);

  let key = await crypto.subtle.importKey(
    "raw",
    passwordBuffer,
    "AES-CBC",
    true,
    ["encrypt", "decrypt"]
  );

  let plainBuffer = await crypto.subtle.decrypt(
    {
      name: "AES-CBC",
      iv: iv
    },
    key,
    buffer
  );
  let array = new Uint8Array(plainBuffer);

  if (outputFormat === "bin") {
    return array;
  } else if(outputFormat === "utf8") {
    return new TextDecoder().decode(array);
  }

  // convert bytes to hex string
  return binToHex(array);
}
