import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  errorMessage: string = "";
  loading: boolean;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  doLogin(loginData) {
    this.errorMessage = '';
    this.loading = true;
    this.loginForm.disable();
    this.authService.login(loginData.username, loginData.password).then(succeed => {
      this.errorMessage = '';
      this.router.navigate(["Home"]);
    }).catch(e => {
      console.error(e);
      this.errorMessage = e.message;
    }).then(() => {
      this.loading = false;
      this.loginForm.enable();
    })
  }

}
